import axios, { defaultConfig } from '@/api/axios-instance'
import { RegistryDto, SERVICE_NAMES, ServiceName } from '@docshouse/dh-ui-types'

const registryServiceBaseUrl = defaultConfig.baseURL.replace(
  SERVICE_NAMES.GATEWAY_API_SERVICE,
  SERVICE_NAMES.REGISTRY_SERVICE
)

export default {
  getRegistryAppsDescription: async () => {
    return axios.get<Record<ServiceName, RegistryDto>>('/applications', {
      baseURL: registryServiceBaseUrl,
      headers: { Accept: 'application/json' },
    })
  },
}
