var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{staticClass:"settings-logger",attrs:{"dense":""},on:{"click":_vm.openSystemLogDialog}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-list-box-outline")])],1),_c('v-list-item-title',{staticClass:"dh-text-body1"},[_vm._v(_vm._s(_vm.$t('buttons.systemLog')))]),_c('dh-dialog',{attrs:{"model":_vm.isSystemLogDialogOpen,"width":"80%","is-hide-actions":""},on:{"update:model":function($event){_vm.isSystemLogDialogOpen=$event},"cancel-clicked":_vm.closeSystemLogDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dialogs.systemLogTitle'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"px-6 py-3"},[_c('dh-data-table',{staticClass:"settings-logger__table",attrs:{"items":_vm.logEvents,"headers":_vm.tableHeaders,"search":_vm.tableSearchQuery,"show-expand":"","expanded":_vm.tableExpanded,"sort-by":"timestamp","sort-desc":"","items-per-page":50,"dense":""},on:{"update:expanded":function($event){_vm.tableExpanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('dh-text-field',{staticClass:"settings-logger__table__search mt-2 mb-6",attrs:{"label":_vm.$t('logEventsSearch'),"hide-details":""},model:{value:(_vm.tableSearchQuery),callback:function ($$v) {_vm.tableSearchQuery=$$v},expression:"tableSearchQuery"}})]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(item.type))])])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$formatDate(item.timestamp, 'dateTime')))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"settings-logger__table__row__details py-2",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"settings-logger__table__row__details__title dh-text-subtitle2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('logEventDetails'))+" ")]),_c('pre',{staticClass:"settings-logger__table__row__details__content"},[_vm._v(_vm._s(item.details))])])]}}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }