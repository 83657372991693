






import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'PageDoesNotExistView',

  created() {
    this.resetActiveTab()
  },

  methods: {
    ...mapActions({
      resetActiveTab: 'ui/resetActiveTab',
    }),
  },
})
