




















































































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { BaseAppInformationForUser, ServiceDescription, ServiceInformationForUser } from '@/types/services.types'
import { SERVICE_ACTIVE_STATUSES, ServiceComponent } from '@docshouse/dh-ui-types'
import { getServiceComponentIdsRecursively, getServiceRootComponent } from '@/common/utils'

export default Vue.extend({
  name: 'SettingsSystemInfo',

  data() {
    return {
      isServicesInfoDialogOpen: false,
      servicesSearchQuery: '',
    }
  },

  computed: {
    ...mapGetters({
      initialSystemSettings: 'settings/initialSystemSettings',
      services: 'services/services',
      servicesArray: 'services/servicesArray',
      translateByNlsId: 'facadeApplications/translateByNlsId',
    }),
    SERVICE_ACTIVE_STATUSES(): typeof SERVICE_ACTIVE_STATUSES {
      return SERVICE_ACTIVE_STATUSES
    },
    baseAppInformation(): BaseAppInformationForUser {
      return {
        name: this.$baseAppName,
        version: process.env.VUE_APP_VERSION,
        isRegistryEnabled: this.initialSystemSettings.ui.isRegistryEnabled,
        isCustomAuthorization: this.initialSystemSettings.ui.isCustomAuthorization,
        isDynamicServicesEnabled: this.initialSystemSettings.ui.isDynamicServicesEnabled,
        isDarkThemeAvailable: this.initialSystemSettings.ui.isDarkThemeAvailable,
        isInternationalizationAvailable: this.initialSystemSettings.ui.isInternationalizationAvailable,
        isSystemInfoAvailable: this.initialSystemSettings.ui.isSystemInfoAvailable,
        isLoggerAvailable: this.initialSystemSettings.ui.isLoggerAvailable,
        isRestoreDefaultsAvailable: this.initialSystemSettings.ui.isRestoreDefaultsAvailable,
        isSearchAvailable: this.initialSystemSettings.ui.isSearchAvailable,
        isNotificationsInBackgroundAvailable: this.initialSystemSettings.ui.isNotificationsInBackgroundAvailable,
        isExpandDrawerOnHover: this.initialSystemSettings.ui.isExpandDrawerOnHover,
        notificationDisplayDuration: this.initialSystemSettings.ui.notificationDisplayDuration,
        notificationConcurrentCount: this.initialSystemSettings.ui.notificationConcurrentCount,
      }
    },
    servicesInformation(): ServiceInformationForUser[] {
      return this.servicesArray
        .filter((service: ServiceDescription) => getServiceRootComponent(service)?.type !== 'web-component-embedded')
        .map((service: ServiceDescription) => {
          return {
            name: service.name,
            description: service.description,
            initializationStatus: service.initializationStatus,
            initializationDetails: service.initializationDetails,
            expansionPanelModel: 0,
            ...(!!service && {
              version: service.version,
              activeStatus: service.isActive ? SERVICE_ACTIVE_STATUSES.ACTIVE : SERVICE_ACTIVE_STATUSES.INACTIVE,
              embeddedServiceComponentNames: getServiceComponentIdsRecursively(service.components),
              isDynamicService: getServiceRootComponent(service)?.meta?.isDynamicService,
            }),
          } as ServiceInformationForUser
        })
    },
    filteredServicesInformation(): ServiceInformationForUser[] {
      return this.servicesInformation.filter((service) => {
        const query = this.servicesSearchQuery.toLowerCase()
        const name = service.name.toLowerCase()
        let title = this.getServiceTitle(service)?.toLowerCase()

        return name.includes(query) || title.includes(query)
      })
    },
  },

  methods: {
    ...mapActions({
      setupServiceComponent: 'services/setupServiceComponent',
    }),
    openServicesInfoDialog() {
      this.isServicesInfoDialogOpen = true
    },

    closeServicesInfoDialog() {
      this.isServicesInfoDialogOpen = false
    },

    getServiceTitle(serviceInformation: ServiceInformationForUser): string {
      return serviceInformation.isDynamicService
        ? this.translateByNlsId(serviceInformation.name)
        : this.$t(`navigationItems.${serviceInformation.name}`)
    },

    getTableCellValue(value: boolean | string | number | null) {
      if (typeof value === 'boolean') {
        return value ? 'mdi-check' : 'mdi-close'
      }
      return value
    },

    forceInitializeService(serviceName: ServiceDescription['name']) {
      const runComponentsSetup = (components?: ServiceComponent[]) => {
        if (!components) return
        components.forEach((component) => {
          component.name = component.id
          this.setupServiceComponent(component)

          runComponentsSetup(component.components)
        })
      }

      const service: ServiceDescription = this.services[serviceName]
      runComponentsSetup(service.components)
    },
  },
})
