import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import { getServiceRootComponent, getTopLevelRouteByPath, navigate } from '@/common/utils'
import { localStorageHandler } from '@docshouse/dh-ui-components'
import _camelCase from 'lodash.camelcase'
import { Commit, Dispatch } from 'vuex'
import { ServiceDescription } from '@/types/services.types'
import { Route } from 'vue-router'
import {
  LOCAL_STORAGE_KEYS,
  NavigationItem,
  OpenTabPayload,
  RouteDescription,
  ServiceDescriptionDto,
  SnackBarMessage,
  Tab,
  UpdateTabPayload,
} from '@docshouse/dh-ui-types'

interface UIState {
  unresolvedInitialNavigationPath: RouteDescription['path'] | null
  unresolvedBackButtonNavigationPath: RouteDescription['path'] | null
  tabs: Tab[]
  snackbarMessages: SnackBarMessage[]
  isDrawerMini: boolean
}

const getDefaultState = () => {
  return {
    unresolvedInitialNavigationPath: null,
    unresolvedBackButtonNavigationPath: null,
    tabs: [
      {
        id: 'root',
        title: '',
        path: '',
        source: '',
        isCreation: false,
        isActive: false,
      },
    ],
    snackbarMessages: [],
    isDrawerMini: true,
  } as UIState
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    unresolvedInitialNavigationPath(state: UIState) {
      return state.unresolvedInitialNavigationPath
    },
    unresolvedBackButtonNavigationPath(state: UIState) {
      return state.unresolvedBackButtonNavigationPath
    },
    tabs(state: UIState) {
      return state.tabs
    },
    activeTab(state: UIState) {
      return state.tabs.find((tab) => tab.isActive)
    },
    activeTabIndex(state: UIState) {
      return state.tabs.findIndex((tab) => tab.isActive)
    },
    snackbarMessages(state: UIState) {
      return state.snackbarMessages
    },
    isDarkThemeAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isDarkThemeAvailable
    },
    isInternationalizationAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isInternationalizationAvailable
    },
    isSystemInfoAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isSystemInfoAvailable
    },
    isLoggerAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isLoggerAvailable
    },
    isRestoreDefaultsAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isRestoreDefaultsAvailable
    },
    isSearchAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isSearchAvailable
    },
    isNotificationsInBackgroundAvailable(state: UIState, getters: any, rootState: any) {
      return rootState.settings.initialSystemSettings?.ui?.isNotificationsInBackgroundAvailable
    },
    notificationDisplayDuration(state: UIState, getters: any, rootState: any): NavigationItem[] {
      return rootState.settings.initialSystemSettings?.ui?.notificationDisplayDuration
    },
    notificationConcurrentCount(state: UIState, getters: any, rootState: any): NavigationItem[] {
      return rootState.settings.initialSystemSettings?.ui?.notificationConcurrentCount
    },
    isDrawerMini(state: UIState) {
      return state.isDrawerMini
    },
    isExpandDrawerOnHover(state: UIState, getters: any, rootState: any): NavigationItem[] {
      return rootState.settings.initialSystemSettings?.ui?.isExpandDrawerOnHover
    },
    navigationItems(state: UIState, getters: any, rootState: any, rootGetters: any): NavigationItem[] {
      const userPermissions = store.getters['auth/userPermissionsMap']
      return rootGetters['services/servicesArray']
        .filter((service: ServiceDescription) => {
          const rootComponent = getServiceRootComponent(service)!
          const isRootService = ['web-component-root', 'custom-app-root'].includes(rootComponent.type)
          const isDynamicService = rootComponent.meta?.isDynamicService
          const isServiceDisabled = rootComponent.meta?.isDisabled
          const serviceHasLocalSettings = rootComponent.meta?.isLocalSettings
          let serviceHasPermissions = !!userPermissions[rootComponent.id]?.resources?.length
          if (rootComponent.id === 'dh-dashboards-service') {
            serviceHasPermissions = true
          }
          if (serviceHasLocalSettings) {
            return isRootService && !isServiceDisabled
          } else if (isDynamicService) {
            const topLevelPermission = (service.permissions as ServiceDescriptionDto['permissions'])?.find(
              (permission) => permission.name === `${_camelCase(rootComponent.id)}-access`
            )
            return (
              isRootService &&
              !isServiceDisabled &&
              // динамический сервис не должен отображаться, если для него не пришло верхнеуровневого разрешения
              !!topLevelPermission
            )
          } else {
            return (
              isRootService &&
              !isServiceDisabled &&
              // статический сервис не должен отображаться, если для него не пришло ни одного разрешения
              serviceHasPermissions
            )
          }
        })
        .sort((a: ServiceDescription, b: ServiceDescription) => {
          const orderA = getServiceRootComponent(a)?.menuOrder ?? Infinity
          const orderB = getServiceRootComponent(b)?.menuOrder ?? Infinity
          return orderA - orderB
        })
        .map((service: ServiceDescription) => {
          const rootComponent = getServiceRootComponent(service)!

          return {
            path: rootComponent?.path,
            titleKey: service.localizedName ?? service.name,
            isTranslateTitleRequired: !service.localizedName,
            icon: rootComponent?.icon,
            isDynamicService: rootComponent?.meta?.isDynamicService,
            isDynamicServiceCustomBundle: rootComponent?.meta?.isDynamicServiceCustomBundle,
          }
        })
    },
  },

  mutations: {
    setUnresolvedInitialNavigationPath(state: UIState, payload: Route['fullPath'] | null) {
      state.unresolvedInitialNavigationPath = payload
    },

    setUnresolvedBackButtonNavigationPath(state: UIState, payload: RouteDescription['path'] | null) {
      state.unresolvedBackButtonNavigationPath = payload
    },

    setTab(state: UIState, tab: Tab) {
      state.tabs.push(tab)
    },

    updateTab(state: UIState, payload: { index: number; data: Tab }) {
      state.tabs.splice(payload.index, 1, payload.data)
    },

    deleteTab(state: UIState, tabIndex: number) {
      state.tabs.splice(tabIndex, 1)
    },

    setTabs(state: UIState, tabs: Tab[]) {
      state.tabs = tabs
    },

    setIsDrawerMini(state: UIState, value: boolean) {
      state.isDrawerMini = value
    },

    setSnackbar(state: UIState, payload: SnackBarMessage) {
      state.snackbarMessages.unshift(payload)
    },

    resetSnackbar(state: UIState, payload: SnackBarMessage[]) {
      state.snackbarMessages = payload
    },

    resetState(state: UIState) {
      Object.assign(state, getDefaultState())
    },
  },

  actions: {
    openTab(
      { state, dispatch, commit }: { state: UIState; dispatch: Dispatch; commit: Commit },
      payload: OpenTabPayload
    ): void {
      dispatch('resetActiveTab')

      const isTabAlreadyExist = state.tabs.some((tab: Tab) => tab.id === payload.id)

      if (!isTabAlreadyExist) {
        if (payload.isCreation) {
          const numberOfExistingCreationTabsOfSameType = state.tabs.filter(
            (tab) =>
              tab.isCreation && tab.source === payload.source && (tab.title as string).includes(payload.title as string)
          ).length
          const nextCreationTabNumber = String(numberOfExistingCreationTabsOfSameType + 1)
          payload.title = `${payload.title} ${nextCreationTabNumber}`
        }

        payload.isActive = true
        commit('setTab', payload)
      } else {
        dispatch('updateTab', { id: payload.id, isActive: true })
      }
    },

    updateTab(
      { state, dispatch, commit }: { state: UIState; dispatch: Dispatch; commit: Commit },
      payload: UpdateTabPayload
    ): void {
      const targetTabIndex = state.tabs.findIndex((tab: Tab) => tab.id === payload.id)
      const isTabExist = targetTabIndex !== -1

      if (payload.isActive) {
        dispatch('resetActiveTab')
      }

      if (isTabExist) {
        const targetTab = state.tabs[targetTabIndex]
        const updatedTab = { ...targetTab, ...payload }

        commit('updateTab', {
          index: targetTabIndex,
          data: updatedTab,
        })
      }
    },

    closeTab({ state, commit }: { state: UIState; commit: Commit }, tabId: Tab['id']): void {
      if (tabId === 'root') {
        // корневую вкладку нельзя закрыть
        return
      }

      const targetTabIndex = state.tabs.findIndex((tab: Tab) => tab.id === tabId)
      const targetTab = state.tabs[targetTabIndex]
      const isTabExist = targetTabIndex !== -1

      if (isTabExist) {
        const targetServiceUnsavedForms = localStorageHandler(
          'get',
          targetTab.source!,
          LOCAL_STORAGE_KEYS.UNSAVED_FORMS
        )
        // для некоторых сложных форм несохраненные изменения могут сохраняться под несколькими ключами, включающими в себя
        // targetTab.id (например uuid1 и uuid1_steps). при закрытии вкладки необходимо удалять вся связанные с данной формой ключи
        const unsavedFormKeysToRemove = targetServiceUnsavedForms
          ? Object.keys(targetServiceUnsavedForms).filter((key) => key.includes(targetTab.id))
          : null

        unsavedFormKeysToRemove?.forEach((key) => {
          localStorageHandler('remove', targetTab.source!, LOCAL_STORAGE_KEYS.UNSAVED_FORMS, key, null, Vue.prototype)
        })

        commit('deleteTab', targetTabIndex)
      }
    },

    closeAllTabs({ state, commit, rootGetters }: { state: UIState; commit: Commit; rootGetters: any }): void {
      const activeTab: Tab = rootGetters['ui/activeTab']

      if (activeTab) {
        const topLevelRoute = getTopLevelRouteByPath(activeTab.path)
        navigate({ path: topLevelRoute! }, true)
      }

      state.tabs
        .filter((tab) => tab.id !== 'root')
        .forEach((tab: Tab) => {
          localStorageHandler('remove', tab.source!, LOCAL_STORAGE_KEYS.UNSAVED_FORMS, tab.id, null, Vue.prototype)
        })
      const updatedTabs = state.tabs.filter((tab) => tab.id === 'root')
      commit('setTabs', updatedTabs)
    },

    resetActiveTab({ state, commit }: { state: UIState; commit: Commit }): void {
      const updatedTabs = state.tabs.map((tab) => ({ ...tab, isActive: false }))
      commit('setTabs', updatedTabs)
    },

    restoreTabs({ commit }: { commit: Commit }, tabs: Tab[]): void {
      commit('setTabs', tabs)
    },

    showSnackbar({ commit, getters }: { commit: Commit; getters: any }, payload: SnackBarMessage) {
      payload.id = uuidv4()
      commit('setSnackbar', payload)

      setTimeout(() => {
        const updatedMessages = getters.snackbarMessages.filter((message: SnackBarMessage) => message.id !== payload.id)
        commit('resetSnackbar', updatedMessages)
      }, getters.notificationDisplayDuration ?? 5000)
    },
  },
}
