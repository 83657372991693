import { navigate } from '@/common/utils'
import { ActionContext, Commit, Dispatch } from 'vuex'
import {
  CustomEventDetail,
  EventPayload,
  EventPayloadBackButtonClicked,
  EventPayloadCloseTab,
  EventPayloadCustomLogin,
  EventPayloadLogEvent,
  EventPayloadMessage,
  EventPayloadOpenTab,
  EventPayloadRedirect,
  EventPayloadServiceSetupFinished,
  EventPayloadUpdateTab,
  SERVICE_NAMES,
  ServiceName,
  SnackBarMessage,
} from '@docshouse/dh-ui-types'
import { showConsoleEventMessage } from '@docshouse/dh-ui-components'

type EventsState = Record<string, never>

const getDefaultState = () => {
  return {} as EventsState
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    resetState(state: EventsState) {
      Object.assign(state, getDefaultState())
    },
  },

  actions: {
    // все коммуникации между сервисами и ui-base происходят через DOM Custom Events
    dispatchGlobalEvent(ctx: ActionContext<EventsState, any>, payload: EventPayload): void {
      const event = new CustomEvent(payload.target, {
        bubbles: true,
        composed: true,
        detail: {
          eventName: payload.eventName,
          payload: payload.payload,
          target: payload.target,
          source: SERVICE_NAMES.BASE,
        } as CustomEventDetail,
      })

      showConsoleEventMessage(event, 'output')

      document.querySelector(payload.target)?.dispatchEvent(event)
    },

    handleGlobalEvent(
      { dispatch, commit, rootGetters }: { dispatch: Dispatch; commit: Commit; rootGetters: any },
      event: CustomEvent
    ): void {
      const details: CustomEventDetail = event.detail
      const isRootComponentSource = details.source in rootGetters['services/services']

      // ui-base должен игнорировать свои собственные сообщения, а так же сообщения из шаблонных динамических скриптов
      // (сообщения из шаблонных динамических скриптов могут приходить только в момент их первичного исполнения, а это
      // происходит только при кэшировании этих скриптов в DOM)
      if ([SERVICE_NAMES.BASE, SERVICE_NAMES.DYNAMIC_SERVICE, SERVICE_NAMES.OBJECTS_SERVICE].includes(details.source)) {
        return
      }

      showConsoleEventMessage(event, 'input')

      switch (details.eventName) {
        case 'service-setup-finished': {
          const payload: EventPayloadServiceSetupFinished['payload'] = details.payload

          // события завершения настройки не из корневых компонентов не нуждаются в обработке
          if (isRootComponentSource) {
            commit(
              'services/setServiceInitializationStatus',
              {
                serviceName: details.source,
                initializationStatus: payload.status,
                initializationDetails: payload.details,
              },
              { root: true }
            )
          }
          break
        }

        case 'service-activated': {
          // события активации не из корневых компонентов не нуждаются в обработке
          if (isRootComponentSource) {
            commit('services/setIsServiceActive', { serviceName: details.source, isActive: true }, { root: true })
          }
          break
        }

        case 'service-deactivated': {
          if (isRootComponentSource) {
            // события деактивации не из корневых компонентов не нуждаются в обработке
            commit('services/setIsServiceActive', { serviceName: details.source, isActive: false }, { root: true })
          }
          break
        }

        case 'message': {
          const payload: EventPayloadMessage['payload'] = details.payload
          dispatch('showMessage', { payload, source: details.source })
          break
        }

        case 'redirect': {
          const payload: EventPayloadRedirect['payload'] = details.payload

          navigate({ path: payload })
          break
        }

        case 'back-button-clicked': {
          // логика подтверждения перед закрытием dirty вкладки организована на ui-base, а кнопка BackButton внутри ui-components.
          // поэтому ui-components лишь сообщает о нажатии на кнопку, а за переход и закрытие вкладки отвечает ui-base
          const payload: EventPayloadBackButtonClicked['payload'] = details.payload
          commit('ui/setUnresolvedBackButtonNavigationPath', payload, { root: true })
          break
        }

        case 'open-tab': {
          const payload: EventPayloadOpenTab['payload'] = details.payload
          dispatch('ui/openTab', payload, { root: true })
          break
        }

        case 'update-tab': {
          const payload: EventPayloadUpdateTab['payload'] = details.payload
          dispatch('ui/updateTab', payload, { root: true })
          break
        }

        case 'close-tab': {
          const payload: EventPayloadCloseTab['payload'] = details.payload
          dispatch('ui/closeTab', payload, { root: true })
          break
        }

        case 'log-event': {
          const payload: EventPayloadLogEvent['payload'] = details.payload
          dispatch('logger/createLogEvent', { payload, source: details.source }, { root: true })
          break
        }

        case 'custom-login': {
          const payload: EventPayloadCustomLogin['payload'] = details.payload
          dispatch('auth/customLogin', { ...payload, isCustomAuthorization: true }, { root: true })
          break
        }

        case 'logout': {
          dispatch('auth/logout', null, { root: true })
          break
        }
      }
    },

    showMessage(
      { dispatch }: { dispatch: Dispatch },
      payload: { payload: SnackBarMessage; source?: ServiceName }
    ): void {
      dispatch('ui/showSnackbar', payload.payload, { root: true })

      dispatch(
        'logger/createLogEvent',
        {
          payload: {
            type: 'notification-ui',
            summary: payload.payload.message,
            details: {
              message: payload.payload.message,
              type: payload.payload.color,
            },
          },
          source: payload.source,
        },
        { root: true }
      )
    },
  },
}
