import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import '@/plugins/dh-ui-components'
import { navigate } from '@/common/utils'
import App from '@/App.vue'
import { SERVICE_NAMES } from '@docshouse/dh-ui-types'
import { IFRAME_PROHIBITED_PARAMS } from '@docshouse/dh-facade-utils-ui'

Vue.config.productionTip = false
Vue.prototype.$baseAppName = SERVICE_NAMES.BASE
Vue.prototype.$navigate = navigate
Vue.prototype.$isDebug = false

function resolveIframeState() {
  const isEmbeddedInIframe = window !== window.parent
  if (isEmbeddedInIframe) {
    store.commit('settings/setIsEmbeddedInIframe', true)

    // извлечение iframeOptions из url происходит в main.ts, так как важно очистить url от лишних параметров прежде
    // чем с ним начнет работать vue router. иначе это приведет к проблемам при инициализации сервисов
    const url = new URL(location.href)
    const ignoredQueryParams = Object.values(IFRAME_PROHIBITED_PARAMS)
    const iframeOptions: Record<string, string> = {}

    try {
      // добавляем переданные query параметры (за исключением ignoredQueryParams) в iframeOptions для последующей передачи
      // в динамические сервисы. удаляем их из url
      url.searchParams.forEach((value, key) => {
        if (!ignoredQueryParams.includes(key)) {
          iframeOptions[key] = value
          url.searchParams.delete(key)
        }
      })
      store.commit('settings/setIframeOptions', iframeOptions)

      // удаление параметра из url
      window.history.replaceState({}, '', url.toString())
    } catch (error) {
      console.error('Failed to parse iframe options:', error)
    }
  }
}

function resolveR7State() {
  const url = new URL(location.href)
  url.searchParams.forEach((value, key) => {
    if (key === 'isHideNavigatePanel') {
      store.commit('settings/setR7Options', true)
    }
  })
}

resolveIframeState()
resolveR7State()

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
