


























































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SettingsTheme from '@/components/settings/SettingsTheme.vue'
import SettingsRestoreDefaults from '@/components/settings/SettingsRestoreDefaults.vue'
import SettingsLocale from '@/components/settings/SettingsLocale.vue'
import SettingsSystemInfo from '@/components/settings/SettingsSystemInfo.vue'
import SettingsLogger from '@/components/settings/SettingsLogger.vue'
import SettingsRole from '@/components/settings/SettingsRole.vue'
import SettingsProfile from '@/components/settings/SettingsProfile.vue'

export default Vue.extend({
  name: 'SettingsAccount',
  components: {
    SettingsRestoreDefaults,
    SettingsTheme,
    SettingsLocale,
    SettingsSystemInfo,
    SettingsLogger,
    SettingsRole,
    SettingsProfile,
  },

  data() {
    return {
      isMenuOpen: false,
      isPinDrawer: false,
    }
  },

  computed: {
    ...mapGetters({
      userRoles: 'auth/userRoles',
      isDrawerMini: 'ui/isDrawerMini',
      isDarkThemeAvailable: 'ui/isDarkThemeAvailable',
      isInternationalizationAvailable: 'ui/isInternationalizationAvailable',
      isSystemInfoAvailable: 'ui/isSystemInfoAvailable',
      isLoggerAvailable: 'ui/isLoggerAvailable',
      isRestoreDefaultsAvailable: 'ui/isRestoreDefaultsAvailable',
      isExpandDrawerOnHover: 'ui/isExpandDrawerOnHover',
      getUserInfo: 'usersInfo/getUserInfo',
    }),
    userAvatarContent(): string {
      return this.getUserInfo?.activeProfile?.preferredUsername
        ? this.getUserInfo?.activeProfile.preferredUsername.slice(0, 1).toUpperCase()
        : this.getUserInfo?.preferredUsername.slice(0, 1).toUpperCase()
    },
    getTitle() {
      return this.getUserInfo?.preferredUsername
    },
    getUserName() {
      const family = this.getUserInfo?.familyName ?? ''
      const given = this.getUserInfo?.givenName ?? ''
      const middle = this.getUserInfo?.middleName ?? ''
      return family + ' ' + given + ' ' + middle
    },
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      showMessage: 'events/showMessage',
    }),
    logoutHandler() {
      // @ts-ignore
      this.logout()
      setTimeout(() => {
        this.$router.push({ path: '/logout' })
      }, 500)
      /*if (process.env.NODE_ENV === 'development') {
        window.location.href = process.env.BASE_URL + process.env.VUE_APP_DEV_LOGOUT
      } else {
        window.location.href = process.env.BASE_URL + process.env.VUE_APP_PROD_LOGOUT
      }
      */
    },
  },
})
