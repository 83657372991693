












import Vue from 'vue'
import { mapGetters } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'
import { getServiceRootComponent } from '@/common/utils'
import { SERVICE_INITIALIZATION_STATUSES, SERVICE_NAMES } from '@docshouse/dh-ui-types'
import { ServiceDescription } from '@/types/services.types'
import ServiceWrapperView from '@/views/ServiceWrapperView.vue'

export default Vue.extend({
  name: 'SettingsNotifications',
  components: { ServiceWrapperView },

  data() {
    return {
      notificationsServiceInBackground: null as ServiceDescription | null,
    }
  },

  computed: {
    ...mapGetters({
      serviceInitializationStatus: 'services/serviceInitializationStatus',
      servicesInitializedArray: 'services/servicesInitializedArray',
    }),
    isNotificationsServiceInitializationInProgress(): boolean {
      return (
        this.serviceInitializationStatus(SERVICE_NAMES.NOTIFICATIONS_SERVICE) ===
        SERVICE_INITIALIZATION_STATUSES.IN_PROGRESS
      )
    },
    isNotificationsServiceInitialized(): boolean {
      return (
        this.serviceInitializationStatus(SERVICE_NAMES.NOTIFICATIONS_SERVICE) ===
        SERVICE_INITIALIZATION_STATUSES.SUCCESS
      )
    },
  },

  watch: {
    isNotificationsServiceInitialized: {
      immediate: true,
      handler: function (isInitialized: boolean) {
        if (isInitialized && !this.notificationsServiceInBackground) {
          this.setNotificationsServiceInBackground()
        }
      },
    },
  },

  methods: {
    // часть микрофронта dh-notifications-service будет всегда работать в фоне и отображаться на ui-base вне зависимости от
    // активности основной части (которая будет отображаться на роуте /notifications-service)
    setNotificationsServiceInBackground() {
      const notificationsService: ServiceDescription = this.servicesInitializedArray.find(
        (service: ServiceDescription) => service.name === SERVICE_NAMES.NOTIFICATIONS_SERVICE
      )

      const notificationsServiceInBackground = _cloneDeep(notificationsService)
      const rootComponent = getServiceRootComponent(notificationsServiceInBackground)!
      rootComponent.properties = {
        ...rootComponent.properties,
        isBackgroundMode: true,
      }

      this.notificationsServiceInBackground = notificationsServiceInBackground
    },
  },
})
