

















































import Vue from 'vue'
import { getTopLevelRouteByServiceName } from '@/common/utils'
import { SERVICE_NAMES } from '@docshouse/dh-ui-types'
import { TranslateResult } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
  name: 'SearchField',

  props: {
    isSearchFieldVisibleModel: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
      search: '',
    }
  },

  computed: {
    isSearchFieldVisible: {
      get: function (): boolean {
        return this.isSearchFieldVisibleModel
      },
      set: function (value: boolean) {
        this.$emit('update:isSearchFieldVisibleModel', value)
      },
    },
    searchItemsOptions(): { label: TranslateResult; value: string }[] {
      return [
        {
          label: this.$t('facetSearch'),
          value: 'facetSearch',
        },
        {
          label: this.$t('queryConstructor'),
          value: 'queryConstructor',
        },
        {
          label: this.$t('savedRequests'),
          value: 'savedRequests',
        },
      ]
    },
  },

  methods: {
    toggleIsSearchFieldVisible() {
      this.isSearchFieldVisible = !this.isSearchFieldVisible
    },

    selectSearchItem(item: string) {
      this.selected = []
      const topLevelRoute = getTopLevelRouteByServiceName(SERVICE_NAMES.QUERIES_SERVICE)
      const isNavigateToSameService = this.$route.fullPath.includes(topLevelRoute)
      this.$navigate({ path: `/${topLevelRoute}/${item}`, query: { updateRootTab: 'true' } }, isNavigateToSameService)

      this.toggleIsSearchFieldVisible()
    },

    runSearch() {
      const topLevelRoute = getTopLevelRouteByServiceName(SERVICE_NAMES.QUERIES_SERVICE)
      const checkRouter = this.$route.fullPath.includes(topLevelRoute)
      const tabId = uuidv4()
      this.$navigate(
        {
          path: `/${topLevelRoute}/facetSearch/new/${tabId}?search=${encodeURIComponent(this.search)}`,
          query: { updateRootTab: 'true' },
        },

        checkRouter
      )
      this.toggleIsSearchFieldVisible()
    },
  },
})
