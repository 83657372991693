import Vue from 'vue'
import api from '@/api/api'
import axiosInstance from '@/api/axios-instance'
import i18n from '@/plugins/i18n'
import { Commit, Dispatch } from 'vuex'
import { RequestPageParams, SERVICE_NAMES } from '@docshouse/dh-ui-types'
import { ApplicationDto, NlsValueDto } from '@docshouse/dh-facade-utils-ui'
import { ServiceDescription } from '@/types/services.types'
import { getServiceRootComponent } from '@/common/utils'

interface ApplicationsState {
  nlsValues: Record<NlsValueDto['nlsId'], NlsValueDto[]>
}

export default {
  namespaced: true,

  state: {
    nlsValues: {},
  } as ApplicationsState,

  getters: {
    translateByNlsId:
      (state: ApplicationsState) =>
      (nlsId: NlsValueDto['nlsId'], locale = i18n.locale) => {
        const targetNlsValue = state.nlsValues[nlsId]?.find((value) => value.locale === locale)
        return targetNlsValue?.value ?? nlsId
      },
  },

  mutations: {
    setNlsValues(state: ApplicationsState, payload: { nlsId: NlsValueDto['id']; nlsValues: NlsValueDto[] }) {
      Vue.set(state.nlsValues, payload.nlsId, payload.nlsValues)
    },
  },

  actions: {
    async getApplicationNlsValuesByNlsId(
      { commit }: { commit: Commit },
      {
        applicationId,
        nlsId,
        params,
      }: {
        applicationId: ApplicationDto['id']
        nlsId: NlsValueDto['id']
        params?: RequestPageParams<NlsValueDto>
      }
    ): Promise<NlsValueDto[]> {
      const response = await api.facade.applications.getApplicationNlsValuesByNlsId(applicationId, nlsId, {
        baseURL: `${axiosInstance.defaults.baseURL}/${SERVICE_NAMES.FACADE_SERVICE}`,
        params,
      })
      const nlsValues = response.data
      commit('setNlsValues', { nlsId, nlsValues })

      return nlsValues
    },

    async getAllDynamicApplicationsNlsValues({ dispatch, rootGetters }: { dispatch: Dispatch; rootGetters: any }) {
      rootGetters['services/servicesArray'].forEach((service: ServiceDescription) => {
        const rootComponent = getServiceRootComponent(service)

        if (!rootComponent?.meta?.isDisabled && rootComponent?.meta?.isDynamicService) {
          const applicationId = rootComponent?.properties?.id
          const nlsId = service.name
          dispatch('facadeApplications/getApplicationNlsValuesByNlsId', { applicationId, nlsId }, { root: true })
        }
      })
    },
  },
}
