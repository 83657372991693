

























































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { LogEvent, TableHeader } from '@docshouse/dh-ui-types'

export default Vue.extend({
  name: 'SettingsLogger',

  data() {
    return {
      isSystemLogDialogOpen: false,
      tableHeaders: [
        { text: 'logEventType', value: 'type', width: 200 },
        { text: 'logEventSource', value: 'source', width: 200 },
        { text: 'logEventSummary', value: 'summary' },
        { text: 'logEventTimestamp', value: 'timestamp', width: 200 },
      ] as TableHeader<LogEvent>[],
      tableExpanded: [],
      tableSearchQuery: '',
    }
  },

  computed: {
    ...mapGetters({
      logEvents: 'logger/logEvents',
    }),
  },
  methods: {
    openSystemLogDialog() {
      this.isSystemLogDialogOpen = true
    },

    closeSystemLogDialog() {
      this.isSystemLogDialogOpen = false
    },
  },
})
