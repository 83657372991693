import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { localStorageHandler } from '@docshouse/dh-ui-components'
import '@mdi/font/css/materialdesignicons.css'
import { LOCAL_STORAGE_KEYS } from '@docshouse/dh-ui-types'
import systemSettings from '../../public/system-settings.json'

Vue.use(Vuetify)

const vuetifyConfig = {
  theme: {
    dark: localStorageHandler('get', false, LOCAL_STORAGE_KEYS.DARK_THEME),
    themes: systemSettings.ui.themes,
    icons: {
      iconfont: 'mdi',
    },
    options: { customProperties: true },
  },
}

localStorageHandler('set', false, LOCAL_STORAGE_KEYS.VUETIFY_CONFIG, null, vuetifyConfig)

export default new Vuetify(vuetifyConfig)
