













import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'SettingsPin',

  props: {
    isPinDrawer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    icon(): string {
      return this.isPinDrawer ? 'mdi-pin' : 'mdi-pin-outline'
    },
    tooltipContent(): TranslateResult {
      return this.isPinDrawer ? this.$t('buttons.pinDrawerOff') : this.$t('buttons.pinDrawerOn')
    },
  },

  methods: {
    togglePinDrawer() {
      this.$emit('update:isPinDrawer', !this.isPinDrawer)
    },
  },
})
