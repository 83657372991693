






import Vue from 'vue'

export default Vue.extend({
  name: 'WorkspaceArea',

  props: {
    isHideNavigationSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
