





































import Vue, { PropType } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getServiceRootComponent } from '@/common/utils'
import { ServiceDescription } from '@/types/services.types'
import { SERVICE_INITIALIZATION_STATUSES, ServiceComponent, ServiceDescriptionDto } from '@docshouse/dh-ui-types'

export default Vue.extend({
  name: 'ServiceWrapperView',

  props: {
    service: {
      type: Object as PropType<ServiceDescription | ServiceDescriptionDto>,
      required: true,
    },
  },

  watch: {
    service: {
      deep: true,
      immediate: true,
      handler: function (currenService: ServiceDescription, lastService?: ServiceDescription) {
        if (currenService.name !== lastService?.name) {
          this.changeServiceHandler(currenService, lastService)
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      systemName: 'settings/systemName',
      iframeOptions: 'settings/iframeOptions',
      r7Options: 'settings/r7Options',
      services: 'services/services',
      serviceInitializationStatus: 'services/serviceInitializationStatus',
      serviceInitializationDetails: 'services/serviceInitializationDetails',
      activeTab: 'ui/activeTab',
      getUserName: 'usersInfo/getUserName',
    }),
    isDynamicComponentVisible(): boolean {
      return this.serviceRootComponent?.type === 'web-component-root'
    },
    serviceRootComponent(): ServiceComponent | undefined {
      return getServiceRootComponent(this.service)
    },
    // плагины i18n внутри сервисов будут синхронно с ui-base переключать свою локаль
    locale(): string {
      return this.$i18n.locale
    },
    // инстанс vuetify внутри сервисов будет синхронно с ui-base переключать свою тему
    isDarkTheme(): boolean {
      return this.$vuetify.theme.dark
    },
    // позволяет выполнить редирект на определенный роут внутри сервиса сразу же, после инициализации этого сервиса
    initialRedirectPath(): string | null {
      if (this.serviceRootComponent?.path !== this.$route.fullPath) {
        return this.$route.fullPath
      } else {
        return null
      }
    },
    // функции могут меняться при смене юзера/роли, поэтому переменная должна быть реактивноЙ, а не прокидываться
    // один раз при регистрации роута
    permissions(): ServiceDescription['permissions'][] {
      return this.services[this.service.name]?.permissions
    },
    SERVICE_INITIALIZATION_STATUSES(): typeof SERVICE_INITIALIZATION_STATUSES {
      return SERVICE_INITIALIZATION_STATUSES
    },
    currentServiceInitializationStatus(): ServiceDescription['initializationStatus'] {
      return this.serviceInitializationStatus(this.service.name)
    },
    currentServiceInitializationDetails(): ServiceDescription['initializationDetails'] {
      return this.serviceInitializationDetails(this.service.name)
    },
    isCurrentServiceInitializedSuccessfully(): boolean {
      return this.currentServiceInitializationStatus === SERVICE_INITIALIZATION_STATUSES.SUCCESS
    },
  },

  methods: {
    ...mapActions({
      setupServiceComponent: 'services/setupServiceComponent',
      initializeCustomApp: 'services/initializeCustomApp',
      destroyCustomApp: 'services/destroyCustomApp',
      updateTab: 'ui/updateTab',
    }),
    ...mapMutations({
      setIsServiceActive: 'services/setIsServiceActive',
    }),

    async changeServiceHandler(currentService: ServiceDescription, lastService?: ServiceDescription) {
      const currentServiceRootComponent = getServiceRootComponent(currentService)
      const lastServiceRootComponent = lastService ? getServiceRootComponent(lastService) : null

      if (!this.isCurrentServiceInitializedSuccessfully) {
        const service: ServiceDescription = this.services[currentService.name]
        await this.runComponentsSetup(service.components, true)
      }

      if (currentServiceRootComponent?.type === 'custom-app-root') {
        if (this.isCurrentServiceInitializedSuccessfully) {
          this.initializeCustomApp(currentService)
          this.setIsServiceActive({ serviceName: currentService.name, isActive: true })

          const rootTabTitle = currentServiceRootComponent?.meta?.isDynamicServiceCustomBundle
            ? currentService.name
            : this.$t(`navigationItems.${currentService.name}`)

          this.updateTab({
            id: 'root',
            title: rootTabTitle,
            path: currentServiceRootComponent?.path,
            source: currentService.name,
            isActive: true,
          })
        }
      }

      if (lastService && lastServiceRootComponent?.type === 'custom-app-root') {
        const isLastServiceInitializedSuccessfully =
          this.serviceInitializationStatus(lastService.name) === SERVICE_INITIALIZATION_STATUSES.SUCCESS
        if (isLastServiceInitializedSuccessfully) {
          this.destroyCustomApp(lastService)
          this.setIsServiceActive({ serviceName: lastService.name, isActive: false })
        }
      }
    },

    async runComponentsSetup(components?: ServiceComponent[], useAwait?: boolean) {
      if (!components) return

      for (const component of components) {
        component.name = component.id

        if (useAwait) {
          // для корневого компонента необходимо дождаться завершения инициализации, что корректно выполнялась
          // последующая логика функции changeServiceHandler
          await this.setupServiceComponent(component)
        } else {
          // для вложенных компонентов такой необходимости нет, могут инициализироваться фоном
          this.setupServiceComponent(component)
        }

        this.runComponentsSetup(component.components, false)
      }
    },
  },
})
