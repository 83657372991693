import router from '@/router'
import store from '@/store'
import { RawLocation } from 'vue-router'
import { SessionData } from '@/types/auth.types'
import {
  RouteDescription,
  ServiceName,
  ServiceComponent,
  ServiceDescriptionDto,
  RegistryDto,
  RegistryApp,
  RegistryApplications,
  RegistryAppDescriptorFull,
} from '@docshouse/dh-ui-types'
import { TranslateResult } from 'vue-i18n'

export async function navigate(location: RawLocation, isNavigateToSameService = false) {
  const currentRoute = router.currentRoute
  const resolvedTargetRoute = router.resolve(location).resolved
  const targetServiceName = getServiceNameByPath(resolvedTargetRoute.fullPath)
  const isLocationAsObject = typeof location === 'object'

  if (isNavigateToSameService) {
    const path = isLocationAsObject ? location.path : location
    const querySeparator = path?.includes('?') ? '&' : '?'
    const resultPath =
      isLocationAsObject && location.query?.updateRootTab ? `${path}${querySeparator}updateRootTab=true` : path

    store.dispatch('events/dispatchGlobalEvent', {
      eventName: 'redirect',
      payload: resultPath,
      target: targetServiceName,
    })
  } else {
    const isTargetRouteEqualsCurrentRoute = resolvedTargetRoute.fullPath === currentRoute.fullPath

    if (!isTargetRouteEqualsCurrentRoute) {
      await new Promise((resolve, reject) => {
        router.push(location, resolve, reject)
      }).catch((error: Error) => error)
    }
  }
}

export function fixRootComponent(servicesSettingsRemote: RegistryDto) {
  for (const keyName of Object.keys(servicesSettingsRemote)) {
    //@ts-ignore
    const component = servicesSettingsRemote[keyName] as any
    if (component.appDescriptor.ui.components.length > 1) {
      //@ts-ignore
      const uiComponent = component.appDescriptor.ui.components.find(
        (elem: any) => elem.id === component.appDescriptor.name
      )
      const bufferComponent = JSON.parse(JSON.stringify(uiComponent))
      component.appDescriptor.ui.components = [bufferComponent]
    }
  }
}

export function convertServicesArray(services: RegistryApplications) {
  return services?.reduce((acc, service: RegistryAppDescriptorFull) => {
    // искусственно создаём компонент если у нас несколько рутовых компонентов
    if (service.ui.components.length > 1) {
      for (const component of service.ui.components) {
        const bufferObj = {
          appDescriptor: {
            ...service,
          },
          endpoints: service.endpoints,
        } as RegistryApp
        bufferObj.appDescriptor.name = component.id
        bufferObj.appDescriptor.localizedName = component.id
        // Лайфак чтобы убить ссылку чтобы скопировать объект
        acc[component.id] = JSON.parse(JSON.stringify(bufferObj))
      }
      return acc
    } else {
      const { name } = service
      acc[name] = {
        appDescriptor: {
          ...service,
        },
        endpoints: service.endpoints,
      } as RegistryApp
      return acc
    }
  }, {} as RegistryDto)
}

export function isRefreshTokenRequired(
  tokenReceiveTimestamp: SessionData['receive_timestamp'],
  tokenExpiresIn: SessionData['expires_in']
): boolean {
  const tokenExpiresInTimestamp = tokenReceiveTimestamp + tokenExpiresIn * 1000
  const tokenExpirationBuffer = getTokenExpirationBuffer(tokenExpiresIn)
  const tokenRefreshRequiredTimestamp = tokenExpiresInTimestamp - tokenExpirationBuffer * 1000
  return Date.now() > tokenRefreshRequiredTimestamp
}

// запас времени до истечения срока жизни токена, достигнув которого можно запускать его обновление
export function getTokenExpirationBuffer(tokenExpiresIn: SessionData['expires_in']): number {
  return tokenExpiresIn * 0.1 // s
}

export function getServiceNameByPath(path: RouteDescription['path']): string | null {
  const topLevelRouteRegex = /\/[^/]+/
  const matches = topLevelRouteRegex.exec(path)

  if (matches?.length) {
    const serviceNameWithoutPrefix = matches[0].replace('/', '')
    const allServiceNames = Object.keys(store.getters['services/services'])
    const serviceNameFull = allServiceNames.find((serviceName) => serviceName.includes(serviceNameWithoutPrefix))

    return serviceNameFull ?? matches[0].replace('/', 'dh-')
  } else {
    return null
  }
}

export function getTopLevelRouteByPath(path: RouteDescription['path']): string | null {
  const topLevelRouteRegex = /\/[^/]+/
  const matches = topLevelRouteRegex.exec(path)
  return matches?.length ? matches[0] : null
}

export function getTopLevelRouteByServiceName(serviceName: ServiceName): string {
  const prefix = serviceName.split('-')[0] + '-'
  return serviceName.replace(prefix, '')
}

export function getServiceRootComponent(service: ServiceDescriptionDto): ServiceComponent | undefined {
  return service.components?.find(
    (component) => component.type === 'web-component-root' || component.type === 'custom-app-root'
  )
}

export function getServiceComponentIdsRecursively(components: ServiceComponent[] | undefined): string[] {
  return (
    components?.reduce<string[]>((acc, component) => {
      acc.push(component.id)
      acc.push(...getServiceComponentIdsRecursively(component.components))
      return acc
    }, []) || []
  )
}

// https://developer.mozilla.org/ru/docs/Glossary/Base64#the_unicode_problem_2
export function b64EncodeUnicode(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      // @ts-ignore
      return String.fromCharCode('0x' + p1)
    })
  )
}

export function showConsoleError({
  message,
  serviceName,
  error,
}: {
  message: string | TranslateResult
  serviceName?: ServiceName
  error?: Error | unknown
}) {
  let errorMessage = message

  if (serviceName) {
    errorMessage = `${serviceName}: ${message}`
  }

  if (error) {
    errorMessage += '\n'
  }

  console.error(errorMessage, error)
}
