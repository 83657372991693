import { render, staticRenderFns } from "./SettingsProfile.vue?vue&type=template&id=339c1486&scoped=true&"
import script from "./SettingsProfile.vue?vue&type=script&lang=ts&"
export * from "./SettingsProfile.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsProfile.vue?vue&type=style&index=0&id=339c1486&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339c1486",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardActions,VIcon,VListItem,VListItemIcon,VListItemTitle,VSpacer})
