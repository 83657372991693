









import Vue, { PropType } from 'vue'
import { LocaleMessages } from 'vue-i18n'

export default Vue.extend({
  name: 'AppOverlay',

  props: {
    message: {
      type: String as PropType<LocaleMessages | string>,
      required: false,
    },
  },
})
