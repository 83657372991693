import { SERVICE_NAMES } from '@docshouse/dh-ui-types'

export const API_CONFIG = {
  BASE_URL_PRODUCTION: '/' + SERVICE_NAMES.GATEWAY_API_SERVICE + '/',
  BASE_URL_DEVELOPMENT: `http://${process.env.VUE_APP_PROXY_HOST}:${process.env.VUE_APP_PROXY_PORT}/proxy/${SERVICE_NAMES.GATEWAY_API_SERVICE}/`,
}

export const USERS_INFO_API_CONFIG = {
  BASE_URL_PRODUCTION: `${'/'}${SERVICE_NAMES.GATEWAY_API_SERVICE}/${SERVICE_NAMES.USERS_INFO_SERVICE}/`,
  BASE_URL_DEVELOPMENT_IN_SERVICE: `http://127.0.0.1:8081/proxy/${SERVICE_NAMES.GATEWAY_API_SERVICE}/${SERVICE_NAMES.USERS_INFO_SERVICE}/`,
  BASE_URL_DEVELOPMENT_IN_BASE_APP: `http://${process.env.VUE_APP_PROXY_HOST}:${process.env.VUE_APP_PROXY_PORT}/proxy/${SERVICE_NAMES.GATEWAY_API_SERVICE}/${SERVICE_NAMES.USERS_INFO_SERVICE}/`,
}

export const GATEWAY_API_CONFIG = {
  BASE_URL_PRODUCTION: `${'/'}${SERVICE_NAMES.GATEWAY_API_SERVICE}/`,
  BASE_URL_DEVELOPMENT_IN_SERVICE: `http://127.0.0.1:8081/proxy/${SERVICE_NAMES.GATEWAY_API_SERVICE}/`,
  BASE_URL_DEVELOPMENT_IN_BASE_APP: `http://${process.env.VUE_APP_PROXY_HOST}:${process.env.VUE_APP_PROXY_PORT}/proxy/${SERVICE_NAMES.GATEWAY_API_SERVICE}/`,
}
