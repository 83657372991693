

























import { LOCAL_STORAGE_KEYS } from '@docshouse/dh-ui-types'
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'SettingsTheme',

  computed: {
    currentThemeTitle(): TranslateResult {
      return this.$vuetify.theme.dark ? this.$t('buttons.darkModeOn') : this.$t('buttons.lightModeOn')
    },
  },

  methods: {
    toggleTheme(isDark: boolean) {
      this.$vuetify.theme.dark = isDark
      this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.DARK_THEME, null, this.$vuetify.theme.dark)
    },
  },
})
