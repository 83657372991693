





































import axios from 'axios'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SettingsRole',

  data() {
    return {
      isServicesRoleDialogOpen: false,
      tableHeaders: [{ text: 'nameRole', value: 'name', width: 200 }],
      tableSearch: '',
    }
  },

  computed: {
    ...mapGetters({
      getUserInfo: 'usersInfo/getUserInfo',
    }),
    userRolesList(): [] {
      const user = this.getUserInfo?.activeProfile ?? this.getUserInfo
      return user?.roles?.map((item: string) => {
        return {
          name: item,
        }
      })
    },
  },

  methods: {
    ...mapActions({
      showMessage: 'events/showMessage',
    }),
    openServicesRoleDialog() {
      this.isServicesRoleDialogOpen = true
    },

    closeServicesRoleDialog() {
      this.isServicesRoleDialogOpen = false
    },
  },
})
