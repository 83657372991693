






import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'DashboardView',

  computed: {
    ...mapGetters({
      systemName: 'settings/systemName',
    }),
  },

  created() {
    this.expandNavigationDrawer()
    this.resetActiveTab()
  },

  methods: {
    ...mapActions({
      resetActiveTab: 'ui/resetActiveTab',
    }),
    ...mapMutations({
      setIsDrawerMini: 'ui/setIsDrawerMini',
    }),

    expandNavigationDrawer() {
      this.setIsDrawerMini(false)
    },
  },
})
