






















import { LOCAL_STORAGE_KEYS, LOCALES } from '@docshouse/dh-ui-types'
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'SettingsLocale',

  computed: {
    currentLocaleTitle(): TranslateResult {
      return this.$t(`buttons.to${this.transformLocalItem(this.$i18n.locale)}`)
    },
    listLocales() {
      return LOCALES
    },
  },

  methods: {
    toggleLocale(local: string) {
      this.$i18n.locale = local
      this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.LOCALE, null, this.$i18n.locale)
    },
    transformLocalItem(str: string) {
      return str[0].toUpperCase() + str.slice(1)
    },
  },
})
