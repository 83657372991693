





























import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { SnackBarMessage } from '@docshouse/dh-ui-types'

export default Vue.extend({
  name: 'AppSnackbars',

  computed: {
    ...mapGetters({
      snackbarMessages: 'ui/snackbarMessages',
      notificationConcurrentCount: 'ui/notificationConcurrentCount',
    }),

    messagesVisible(): SnackBarMessage[] {
      return this.snackbarMessages.length > this.notificationConcurrentCount
        ? this.snackbarMessages.slice(0, this.notificationConcurrentCount)
        : this.snackbarMessages
    },
  },

  methods: {
    ...mapMutations({
      resetSnackbar: 'ui/resetSnackbar',
    }),

    closeSnackbarHandler(id: SnackBarMessage['id']) {
      const updatedMessages = this.messagesVisible.filter((message) => message.id !== id)
      this.resetSnackbar(updatedMessages)
    },

    getMessageIcon(color: SnackBarMessage['color']): string {
      switch (color) {
        case 'error':
          return 'mdi-close-circle-outline'
        case 'warning':
          return 'mdi-alert-outline'
        default:
          return 'mdi-check-circle-outline'
      }
    },
  },
})
