



































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NavigationSidebarList from '@/components/common/NavigationSidebarList.vue'
import SettingsPin from '@/components/settings/SettingsPin.vue'

export default Vue.extend({
  name: 'NavigationSidebar',
  components: {
    NavigationSidebarList,
    SettingsPin,
  },

  data() {
    return {
      isPinDrawer: false,
    }
  },

  computed: {
    ...mapGetters({
      systemName: 'settings/systemName',
      isDrawerMini: 'ui/isDrawerMini',
      isExpandDrawerOnHover: 'ui/isExpandDrawerOnHover',
    }),
    mouseenterEvent(): string | null {
      return this.isExpandDrawerOnHover && !this.isPinDrawer ? 'mouseenter' : null
    },
    mouseleaveEvent(): string | null {
      return this.isExpandDrawerOnHover && !this.isPinDrawer ? 'mouseleave' : null
    },
  },

  methods: {
    ...mapActions({
      resetActiveTab: 'ui/resetActiveTab',
    }),
    ...mapMutations({
      setIsDrawerMini: 'ui/setIsDrawerMini',
    }),

    toggleDrawerMini(value: boolean | PointerEvent) {
      this.setIsDrawerMini(typeof value === 'boolean' ? value : !this.isDrawerMini)
    },

    navigationItemClickHandler() {
      this.resetActiveTab()
      if (!this.isPinDrawer && !this.isDrawerMini) {
        this.toggleDrawerMini(true)
      }
    },
  },
})
