import api from '@/api/api'
import { ActionContext } from 'vuex'

export type UsersInfoState = Record<string, never>

export default {
  namespaced: true,

  state: {} as UsersInfoState,

  getters: {},

  mutations: {},

  actions: {
    async switchUserProfile(ctx: ActionContext<UsersInfoState, any>, payload: any): Promise<unknown> {
      const response = await api.gateway.switchUserProfile(payload)
      return response.data
    },
    async exitUserProfile(ctx: ActionContext<UsersInfoState, any>, payload: any): Promise<unknown> {
      const response = await api.gateway.exitUserProfile(payload)
      return response.data
    },
  },
}
