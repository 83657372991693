





























import Vue from 'vue'
import { mapGetters } from 'vuex'
import { NavigationItem } from '@docshouse/dh-ui-types'
import { getTopLevelRouteByPath } from '@/common/utils'

export default Vue.extend({
  name: 'NavigationSidebarList',

  computed: {
    ...mapGetters({
      navigationItems: 'ui/navigationItems',
      isServiceActive: 'services/isServiceActive',
      translateByNlsId: 'facadeApplications/translateByNlsId',
    }),
  },

  methods: {
    getTopLevelRouteByPath,
    getNavigationItemTitle(item: NavigationItem) {
      if (item.isDynamicService) {
        return this.translateByNlsId(item.titleKey)
      } else {
        return item.isTranslateTitleRequired ? this.$t(`navigationItems.${item.titleKey}`) : item.titleKey
      }
    },
    navigationItemClickHandler(item: NavigationItem) {
      if (getTopLevelRouteByPath(this.$route.path) === item.path) {
        return
      }

      const isUpdateRootTabRequired = !item.isDynamicServiceCustomBundle

      this.$navigate({ path: item.path, query: isUpdateRootTabRequired ? { updateRootTab: 'true' } : {} })
      this.$emit('navigation-item-clicked')
      // в соответствии с требованиями, обновление корневой вкладки происходит в следующих случаях:
      // - первичный переход в сервис после авторизации
      // - переход в другой сервис путем нажатия на него в NavigationSidebar ui-base
      // - переход на корневую workspace сервиса путем нажатия на него в NavigationSidebar сервиса или на элемент breadcrumbs
    },
  },
})
