import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from '@/common/messages'
import { dateTimeFormats } from '@/common/date-time-formats'
import { LOCALES } from '@docshouse/dh-ui-types'

Vue.use(VueI18n)

function getLocale(browserLanguage: string) {
  const defaultLocale = LOCALES.RU
  const preferredLocale = localStorage.getItem('locale')

  if (preferredLocale) {
    return JSON.parse(preferredLocale)
  } else {
    for (const availableLocale of Object.values(LOCALES)) {
      const regExp = new RegExp(`^${availableLocale}\\b`)
      if (regExp.test(browserLanguage)) {
        return availableLocale
      }
    }

    return defaultLocale
  }
}

export default new VueI18n({
  locale: getLocale(window.navigator.language),
  messages,
  dateTimeFormats,
})
