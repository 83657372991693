import axios from '@/api/axios-instance'
import { RegistryDto, ServiceName } from '@docshouse/dh-ui-types'

export default {
  switchUserProfile: async (payload: any) => {
    return axios.post<Record<ServiceName, RegistryDto>>('/api/v1/user-profile/switch', payload, {
      headers: {
        Authorization: '',
        Cookie: 'SESSION=16c95d00-3981-4875-ba2c-80db04692b3f',
      },
    })
  },
  exitUserProfile: async (payload: any) => {
    return axios.post<Record<ServiceName, RegistryDto>>('/api/v1/user-profile/exit', payload, {
      headers: {
        Authorization: '',
        Cookie: 'SESSION=16c95d00-3981-4875-ba2c-80db04692b3f',
      },
    })
  },
}
