import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth.store'
import settings from '@/store/modules/settings.store'
import services from '@/store/modules/services.store'
import events from '@/store/modules/events.store'
import ui from '@/store/modules/ui.store'
import logger from '@/store/modules/logger.store'
import usersInfo from '@/store/modules/usersInfo.store'
import facadeApplications from '@/store/modules/facade-applications.store'
import gateway from '@/store/modules/gateway.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    services,
    events,
    ui,
    logger,
    usersInfo,
    facadeApplications,
    gateway,
  },
})
