import { Commit } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { DispatchLogEventPayload, LogEvent, SERVICE_NAMES, ServiceName } from '@docshouse/dh-ui-types'

interface LoggerState {
  logEvents: LogEvent[]
}

const getDefaultState = () => {
  return {
    logEvents: [],
  } as LoggerState
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    logEvents(state: LoggerState) {
      return state.logEvents
    },
  },

  mutations: {
    setLogEvent(state: LoggerState, payload: LogEvent) {
      state.logEvents.push(payload)
    },

    resetState(state: LoggerState) {
      Object.assign(state, getDefaultState())
    },
  },

  actions: {
    createLogEvent(
      { commit }: { commit: Commit },
      payload: {
        payload: DispatchLogEventPayload
        source: ServiceName
      }
    ): void {
      const updatedPayload: LogEvent = {
        ...payload.payload,
        id: uuidv4(),
        timestamp: Date.now(),
        source: payload.source ?? SERVICE_NAMES.BASE,
      }
      commit('setLogEvent', updatedPayload)
    },
  },
}
