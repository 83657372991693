import axios from '@/api/axios-instance'
import { LoginActionPayload, PermitsUiDto, SessionData, SessionDataDto } from '@/types/auth.types'
import { RequestPageParams, SERVICE_NAMES } from '@docshouse/dh-ui-types'

const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
const authUrl = 'api/auth'
const accessUrl = `${SERVICE_NAMES.ACCESS_RIGHTS_SERVICE}/api/v1/dha/access`

export default {
  login: (payload: LoginActionPayload) => {
    const params = new URLSearchParams()
    params.append('grant_type', 'password')
    params.append('username', payload.login)
    params.append('password', payload.password)
    params.append('client_id', process.env.VUE_APP_KEYCLOAK_CLIENT_ID)

    return axios.post<SessionData>(authUrl, params, { headers })
  },

  refreshToken: (payload: SessionData['refresh_token']) => {
    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('refresh_token', payload)
    params.append('client_id', process.env.VUE_APP_KEYCLOAK_CLIENT_ID)

    return axios.post<SessionDataDto>(authUrl, params, { headers })
  },

  getUserPermissions: (roles: string[], params?: RequestPageParams<PermitsUiDto>) => {
    return axios.post<PermitsUiDto[]>(`${accessUrl}/roles`, roles, { params })
  },
}
